.NavBar {
    width: 100%;
    height: auto;
    background: none;
    margin-bottom: -2vh;
    background-color: var(--color-primary);
}
.NavBar-container{
    display: flex;
    padding: 3vh 5vw 0vh 5vw;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
}
.NavBar-container>a, .NavBar-container>section{
    width: 20%;
    z-index: 99;
    text-decoration: none;
}
.NavBar-container>section>img {
    width: 100%;
}
.MarketPlace-button{
    outline: none;
    background-color: var(--color-black);
    padding: clamp(12px, 0.5vw, 20px) clamp(14px, 0.6vw, 25px);
    border-radius: 6px;

    font-family: var(--font-family-content-primary);
    font-weight: 500;
    font-size: clamp(14px, 1.3vw, 25px);
    line-height: clamp(14px, 1.3vw, 25px);
    text-align: center;
    color: var(--color-secondary);

    z-index: inherit;
}

.MarketPlace-button:hover{
    cursor: pointer;
    color: var(--color-black);
    background-color: var(--color-secondary);
}

.MarketPlace-button:active {
    box-shadow: 0px 0px 10px var(--color-shadow);
}

.NavBar-menu {
    width: 45vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 99;
}

.NavBar-menu>section {
    font-family: var(--font-family-content-primary);
    font-weight: 500;
    font-size:clamp(17px, 1.3vw, 25px);
    line-height: clamp(17px, 1.3vw, 25px);
    text-align: center;
    text-decoration: none;
    color: var(--color-black);
}

.NavBar-menu>section:hover {
    cursor: pointer;
    color: var(--color-white);
}

@media screen and (max-width:1025px) and (orientation:portrait) {
    .NavBar-container {
        display:none
    }
}

@media screen and (max-width:1000px) and (orientation:landscape) {
    .NavBar-container {
        display:none
    }
}
