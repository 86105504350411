.Team{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6vh;
    margin-bottom: 10vh;
}

.Team-title {
    font-family: var(--font-family-title);
    font-weight: 400;
    font-size: clamp(40px, 4.6vw, 90px);
    line-height: clamp(40px, 4.6vw, 90px);
    text-align: center;
    color: var(--color-primary);
    text-transform: capitalize;
}

.Team-description {
    font-family: var(--font-family-content-primary);
    font-weight: 400;
    font-size: clamp(20px, 1.7vw, 40px);
    line-height: 150%;
    text-align: center;
    
    color: var(--color-white);
    margin:5vh 10vw 5vh 10vw;
}
.Team-content-container {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Team-card {
    width:20vw;
    min-width: 230px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem 2vw;
    /* z-index:-1 !important; */
}

.Team-card>img {
    border-radius:16px ;
    margin-bottom: 8%;
    /* z-index:-1 !important; */
     
}
.Team-card-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5vw;
    position: relative;
}

.Team-card-description>section{
    width: 100%;
    font-family: var(--font-family-content-secondary);
    font-weight: 300;
    font-size: clamp(10px, 0.8vw, 18px);
    line-height: 171%;
    color: var(--color-grey);
}
.Team-card-description>section>span {
    font-family: var(--font-family-content-secondary);
    font-weight: 600;
    font-size: clamp(20px, 1.8vw, 45px);
    line-height: 150%;
    color: var(--color-white);
}

.Team-card-links {
    width: 7em;
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;
    padding-right: 0.2vw;
}
.Team-card-links>div{
    width: 2em;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2%;
    /* z-index: 99 !important; */
    
}
.Team-card-links>div>a {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    transition: 0.15s ease-in-out;
    /* z-index: 99 !important; */
}

.Team-card-links>div>a>img {
    width: 1.3em;
    height: 100%;
    object-fit: contain;
    /* z-index: 99 !important; */
}

.Team-card-links>div>a:hover{
    background-color: var(--color-white);
    transition: 0.15s ease-in-out;
}
.teamTextIn {
    animation: downInAnimation 0.75s ease-in-out forwards; 
}
.teamTextOut {
    animation: upOutAnimation 0.5s ease-in-out forwards;
}
.team1In, .team3In, .team5In {
    animation: downInAnimation 0.75s ease-in-out forwards;
}
.team2In, .team4In {
    animation: upInAnimation 0.75s ease-in-out forwards;
}
.team1Out, .team3Out, .team5Out {
    animation: upOutAnimation 0.5s ease-in-out forwards;
}
.team2Out, .team4Out {
    animation: downOutAnimation 0.5s ease-in-out forwards;
}


@media screen and (max-width:1025px) {
    .Team-card{
        margin: 1rem 0.5rem;
    }
}

@media screen and (max-width:500px) {
    .Team-card {
        min-width: unset;
        width: 85vw;
    }
    .Team-title {
        margin-top: 5vh;
        width:80vw;
    }
    .teamTextIn{
        animation:upInAnimation 0.5s ease-in forwards;
    }
    .teamTextOut {
        animation:upOutAnimation 0.5s ease-in forwards;   
    }


    .team1In,.team2In, .team3In, .team4In, .team5In {
        animation: fadeInFromLeft 0.5s ease-in-out forwards;
    }
    .teamDescriptionIn {
        animation: fadeInFromRight 0.5s ease-in-out forwards;
    }
    .team1Out, .team2Out, .team3Out, .team4Out, .team5Out {
        animation: fadeOutToLeft 0.5s ease-in-out forwards;
    }
    .teamDescriptionOut {
        animation: fadeOutToRight 0.5s ease-in-out forwards;
    }



}

@keyframes fadeInFromRight {
    0% {opacity: 0; transform: translateX(100%);}
    100% {opacity: 1; transform: translateX(0);}
}
@keyframes fadeInFromLeft {
    0% {opacity: 0; transform: translateX(-50%);}
    100% {opacity: 1; transform: translateX(0);}
}
@keyframes fadeOutToRight {
    0% {opacity: 1; transform: translateX(0%);}
    100% {opacity: 0; transform: translateX(100%);}
}
@keyframes fadeOutToLeft {
    0% {opacity: 1; transform: translateX(0%);}
    100% {opacity: 0; transform: translateX(-50%);}
}