.WebsiteView {
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.Screen-BG-Blur {
    width: 50%;
    height: 50%;
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}
.Screen-BG-Blur>img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.NavBar-mobile-container {
    display: none;
}
.NavBar-mobile-menu-container{
    display: none;
}
.NavBar-mobile-menu>section:nth-of-type(2){
    opacity: 0;
    animation: linkUpInAnimation 0.35s ease-in-out forwards;
}
.NavBar-mobile-menu>section:nth-of-type(3){
    opacity: 0;
    animation: linkUpInAnimation 0.3s ease-in-out 0.18s forwards;
}
.NavBar-mobile-menu>section:nth-of-type(4){
    opacity: 0;
    animation: linkUpInAnimation 0.3s ease-in-out 0.3s forwards;
}
.NavBar-mobile-menu>section:nth-of-type(5){
    opacity: 0;
    animation: linkUpInAnimation 0.3s ease-in-out 0.4s forwards;
}


@media screen and (max-width:1025px) and (orientation:portrait) {
    .Screen-BG-Blur {
        display: none;
    }
    .NavBar-mobile-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vh 3vw 3vh 5vw;
        background-color: var(--color-primary);
        margin-bottom: -1rem; 
    }
    .NavBar-mobile-container>img:nth-of-type(1) {
        width: 40vw;
        margin-top: 2vh;
    }
    .NavBar-mobile-container>img:nth-of-type(2) {
        width:clamp(10px, 6vw, 100px);
        z-index: 99;
        position: fixed;
        top:5vh;
        right:5vw;
        mix-blend-mode: difference;
    }
    .NavBar-mobile-menu-container {
        width: 100vw;
        height:100vh;
        position: fixed;
        top: 0;
        left: 0;
        
        background-color: var(--color-primary);
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .NavBar-mobile-menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .NavBar-mobile-menu section {
        font-family: var(--font-family-content-primary);
        font-weight: 500;
        font-size:clamp(14px, 5vw, 70px);
        line-height: clamp(14px, 5vw, 70px);
        text-align: center;
        text-decoration: none;
        color: var(--color-black);

        margin: 2vh 0;

        z-index: inherit;
    }

    .NavBar-mobile-menu-image {
        position: absolute;
        top:1vh;
        left:5vw
    }
    .NavBar-mobile-menu-image>img {
        width: 40vw;
        object-fit: contain;
    }
    .NavBarView {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
    .MarketPlace-button {
        width:auto;
        height: auto;
        font-size:clamp(14px, 4vw, 70px) !important;
        line-height: clamp(14px, 4vw, 70px) !important ;
        padding: 2vw 3vw !important;
        margin-top: 5vh;
    }
} 

@media screen and (max-width:1000px) and (orientation:landscape) {
    .Screen-BG-Blur {
        display: none;
    }
    .NavBar-mobile-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2vh 2vw 2vh 4vw;
        background-color: var(--color-primary);
        margin-bottom: -1rem; 
    }
    .NavBar-mobile-container>img:nth-of-type(1) {
        width: 30vw;
        margin-top: 2vh;
    }
    .NavBar-mobile-container>img:nth-of-type(2) {
        width:clamp(10px, 5vw, 100px);
        z-index: 99;
        position: fixed;
        top:4vh;
        right:4vw;
        mix-blend-mode: difference;
    }
    .NavBar-mobile-menu-container {
        width: 100vw;
        height:100vh;
        position: fixed;
        top: 0vh;
        left: 0;
        
        background-color: var(--color-primary);
        z-index: 5;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .NavBar-mobile-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .NavBar-mobile-menu section {
        font-family: var(--font-family-content-primary);
        font-weight: 500;
        font-size:clamp(14px, 4vw, 70px);
        line-height: clamp(14px, 4vw, 70px);
        text-align: center;
        text-decoration: none;
        color: var(--color-black);

        margin: 3vh 2vw;

        z-index: inherit;
    }
    .NavBar-mobile-menu section:nth-of-type(1) {
        margin: 0;
    }

    .NavBar-mobile-menu-image {
        position: absolute;
        top:2vh;
        left:4vw
    }
    .NavBar-mobile-menu-image>img {
        width: 30vw;
        object-fit: contain;
    }
    .NavBarView {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
    .MarketPlace-button {
        width:auto;
        height: auto;
        font-size:clamp(14px, 3vw, 70px) !important;
        line-height: clamp(14px, 3vw, 70px) !important ;
        padding: 2vw 3vw !important;
        margin: 5vh 30vw!important;
        align-self: center;
        flex-grow: none;
    }
}
@media screen and (max-width:950px) and (max-height:500px) and (orientation:landscape)  {
    .NavBar-mobile-menu  {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 10vh 0 0 0;
        align-self: center;
    }
    .NavBar-mobile-menu>section{
        width: auto;
        padding: 2vh 2vw;
    }
    .NavBar-mobile-menu>section:nth-of-type(1) {
        padding: 0;
        margin: 0;
    }
}

@media screen and (max-width:500px) and (orientation:portrait) {
    .NavBar-mobile-container>img {
        width: 8vw;
        top:4vh;
        right:1rem
    }
    .NavBar-mobile-container>img:nth-of-type(1){
        width:53vw;
    }
    .NavBar-mobile-menu-image {
        width:53vw;
        height: auto;
    }
    .NavBar-mobile-menu-image>img {
        width: 100%;
    }
    .NavBar-mobile-menu {
        height: 70vh;
        justify-content: space-evenly;
        align-self: flex-end;
        margin-bottom: 10vh;
    }
    .NavBar-mobile-menu>section {
        font-size:clamp(20px, 8vw, 70px);
        line-height:100%;
    }
    .MarketPlace-button{
        width: auto;
        font-size:clamp(25px, 7vw, 70px) !important;
        line-height: 120% !important ;
        padding: 2vh 5vw !important;
        margin: 5vh auto!important;
    }
}

@keyframes linkUpInAnimation {
    0%{opacity: 0; transform:translateY(100%);}
    70%{opacity: 1; transform:translateY(-40%);}
    100%{opacity: 1; transform:translateY(0%);}
}