@font-face {
  font-family: "Koulen";
  src: url("./Assets/Fonts/Koulen-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("./Assets/Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Axiforma";
  src: url("./Assets/Fonts/Axiforma.ttf");
}

body {
  margin: 0;
  font-family: "Koulen", "Poppins", "Axiforma" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  --color-primary: #9eff95;
  --color-secondary: #00ff0a;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-grey: #b4b3ba;
  --color-shadow: #677c45ab;
  --color-banana-primary: #fecf0a;
  --color-banana-secondary: #e08311;
  --color-wolf-primary: #dddddd;
  --color-wolf-secondary: #888888;
  --color-alphacas-primary: #d3a9e3;
  --color-alphacas-secondary: #a45eac;
  --color-foxes-primary: #add8e6;
  --color-foxes-secondary: #336699;
  --font-family-title: "Koulen";
  --font-family-content-primary: "Poppins";
  --font-family-content-secondary: "Axiforma";

  box-sizing: border-box;
  scroll-behavior: smooth;
}

@keyframes downInAnimation {
  0% {
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}
@keyframes downOutAnimation {
  0% {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(30%);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes upInAnimation {
  0% {
    transform: translateY(50%);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}
@keyframes upOutAnimation {
  0% {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0;
    visibility: hidden;
  }
}
