.HomeScreen {
    width: 100%;
    height: auto;
    background-color: var(--color-primary);
    margin: 0;
    overflow-x: hidden;
    position: relative;
}

.HomeScreen-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}
.HomeScreen-content>span {
    font-family: var(--font-family-title);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(38px, 4.6vw, 130px);
    line-height: 130%;
    color: var(--color-black);

    text-transform: uppercase;
    padding-left: 8vw;
    margin-right: -8%;
    margin-top: -7%;


    opacity: 0;

    z-index: 3;

}

h4 {
    margin: 2vh 0;
    padding: 0;
    font-weight: 300;
}
.HomeScreen-content>img {
    width: auto;
    margin-right: -3vw;
    height: clamp(100px, 88vh, 1300px);
    object-fit: contain;
}

.textIn {
    animation: downInAnimation 0.5s ease-out forwards;
}
.textOut {
    animation: upOutAnimation 0.5s ease-in-out forwards;
}

@media screen and (max-width:1450px) and (min-height:760px) and (orientation:landscape) {
    .HomeScreen {
        height: 100%;
    }
    .HomeScreen-content {
        height: 100%;
        flex-direction: column;
    }
    .HomeScreen-content>span {
        width: 40%;
        text-align: center;
        padding: 5vh 0;
        margin: 15vh 0 -10vh 3vw;
        align-self: flex-start;
    }
    .HomeScreen-content>img {
        height: clamp(100px, 60vh, 1300px);  
        align-self:flex-end ;
    }
}

@media screen and (max-width:1025px) and (orientation:portrait) {
    .HomeScreen {
        height: 100%;
    }
    .HomeScreen-content {
        height: 100%;
        flex-direction: column;
    }

    .HomeScreen-content>span {
        width: 70%;
        text-align: left;
        padding: 5vh 5vw;
        margin: 10vh 0 10% 3rem;
        align-self: flex-start;
        font-size: clamp(40px, 5.5vh, 130px);
    }
    .HomeScreen-content>img {
        height: auto;
        width: 100%;  
        align-self:center ;
    }
}

@media screen and (max-width:750px) and (orientation:landscape) {
    .HomeScreen {
        height: 100%;
    }
    .HomeScreen-content {
        height: 100%;
        flex-direction: column;
    }
    .HomeScreen-content>span {
        width: 40%;
        text-align: left;
        padding: 5vh 0;
        margin: 10vh 0 -15vh 5vw;
        align-self: flex-start;
    }
    .HomeScreen-content>img {
        height: clamp(100px, 60vh, 1300px);  
        align-self:flex-end ;
    }  
}

@media screen and (max-width:750px) and (max-height:500px) and (orientation:landscape) {
    .HomeScreen-content {
        height: auto;
        flex-direction: row;
    }
    .HomeScreen-content>span {
        padding: 0;
        margin-top: 8vh;
    }
    .HomeScreen-content>img {
        height: clamp(100px, 80vh, 1300px);  
        align-self:flex-end ;
    }  
}

@media screen and (max-width:500px) and (orientation:portrait) {
    .HomeScreen-content>span {
        width: 100%;
        font-size: clamp(40px, 12vw, 60px);
        line-height: 120%;
        margin-left: 1rem;
        margin-top: clamp(0px, 15vh, 100px);
        margin-bottom: 10vh;
    }
    h3{
       margin-top: 3vh; 
    }
    .HomeScreen-content>img{
        margin:0;
    }
}