.Roadmap {
    width: 100%;
    height: auto;
    padding-top: 8vh;
    margin-bottom: 8vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Roadmap-title {
    font-family: var(--font-family-title);
    font-weight: 400;
    font-size: clamp(40px, 4.6vw, 90px);
    line-height: clamp(40px, 4.6vw, 90px);
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 7vh;
}

.Roadmap-content-container {
    width: 85%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;

}

.Roadmap-content-card {
    min-height: 13em;
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--color-primary);
    border-radius: 12px;
    padding: 1rem 1rem 1rem 1.5rem;
    position: relative;
}
.Roadmap-content-card>img{
    width: 35%;
    height: auto;
    max-height: 320px;
    object-fit: contain;
    align-self: flex-end ;
    margin-bottom: -1em;

    filter: grayscale(1);
}
.Roadmap-content-card:nth-of-type(1)>img {
    width:40%;
}
.Roadmap-content {
    width: 70%;
    display: flex;
    flex-direction: column;
}
.Roadmap-content-title {
    font-family: var(--font-family-content-primary);
    font-weight: 600;
    font-size: clamp(15px, 1.3vw, 25px);
    line-height: 140%;
    color: var(--color-primary);
    list-style: none;
}

.Roadmap-content>ul{
    padding-left: 0.75rem;
}
.Roadmap-content>ul>li {
    font-family: var(--font-family-content-primary);
    font-weight: 400;
    font-size: clamp(13px, 1vw, 20px);
    line-height: 130%;
    color: var(--color-white);
}

.Roadmap-content-card:hover {
    background-color: var(--color-primary);
    transition: 0.35s;
}

.Roadmap-content-card:hover .Roadmap-content-title, .Roadmap-content-card:hover ul>li{
    color: var(--color-black);
    transition: 0.35s;
}

.Roadmap-content-card:hover>img {
    filter: grayscale(0);
    transition: 0.25s;
}

.road1In, .road2In {
    animation: downInAnimation 0.75s ease-in-out forwards;
}
.road1Out, .road2Out {
    animation: upOutAnimation 0.5s ease-in-out forwards ;
}

.road3In, .road4In {
    animation: upInAnimation 0.75s ease-in-out forwards;
}
.road3Out, .road4Out {
    animation: downOutAnimation 0.5s ease-in-out forwards ;
}

@media screen and (max-width:1050px) {
    .Roadmap-content-container {
        width: 60%;
        grid-template-columns: 1fr;
        grid-gap:5vh;
    }
    .Roadmap-content-card {
        min-height: 250px;
    }
    .road3In {
        animation: downInAnimation 0.75s ease-in-out forwards;
    }
    .road3Out {
        animation: upOutAnimation 0.5s ease-in-out forwards ;
    }
    
    .road2In{
        animation: upInAnimation 0.75s ease-in-out forwards;
    }
    .road2Out{
        animation: downOutAnimation 0.5s ease-in-out forwards ;
    }
}

@media screen and (max-width:750px) and (orientation:landscape) {
    .Roadmap-content-container {
        width: 80%;
    }
}

@media screen and (max-width:550px) {
    .Roadmap-content-container {
        width: 80%;
        grid-template-columns: 1fr;
        grid-gap:5vh;
    }
    .Roadmap-content-card {
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .Roadmap-content {
        width: 95%;
    }
    .Roadmap-content-card>img {
        width: 85%;
        height: auto;
        position: unset;
        align-self: center;
        margin-bottom: -1rem;
    }
    .Roadmap-content-card:nth-of-type(1)>img {
        width:95%;
    }

}
