.Footer-container {
    width: 100%;
    height: auto;
    margin-top: 10vh;
}

.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer>img {
    width: clamp(194px, 24vw, 1000px) ;
    object-fit: contain;
    cursor: pointer;
    margin: 4vh 0;
}

.Footer-NavBar-menu {
    width:clamp(270px, 60vw, 1000px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1vh 0;
}
.Footer-NavBar-menu>section, .Footer-copyright {
    text-decoration: none;
    font-family: var(--font-family-content-primary);
    font-weight: 500;
    font-size: clamp(12px, 1vw, 25px);
    line-height: 100%;
    text-align: center;
    color:  var(--color-white);
    
}
.Footer-NavBar-menu>section {
    font-size: clamp(14px, 1.1vw, 25px);
    margin: 1vh 2vw;
    cursor: pointer;
}
.Footer-copyright {
    font-size: clamp(12px, 1vw, 22px);
    margin-top: 4vh;
    margin-bottom: 1vh;
}

.Footer-NavBar-menu>section:hover {
    color: var(--color-primary);
}

.Footer-contact-links {
    width: clamp(270px, 20vw, 1000px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1vh;
    margin-top: 4vh;
}

.Footer-contact-links>a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    width:clamp(38px, 3vw, 100px) ;
    height: clamp(38px, 3vw, 100px) ;
    border-radius: 50%;
    transition: 0.15s ease-in-out;
}

.Footer-contact-links>a>img {
    width: clamp(20px, 1.8vw, 100px);
    height: clamp(20px, 1.8vw, 100px);
    object-fit: contain;
}
.Footer-contact-links>a:hover{
    background-color: var(--color-white);
    transition: 0.15s ease-in-out;
}

.Footer-image {
    width: 100%;
    height: auto;
    min-height: 180px;
    display: flex;
    justify-content:center;
    background-image: linear-gradient(var(--color-black), var(--color-primary) );
}

.Footer-image>img {
    width: 95%;
    height: auto;
    object-fit: contain;
    align-self:flex-end ;
}

.footerIn {
    animation: upInAnimation 0.75s ease-in-out forwards;
}

.footerOut {
    animation: downOutAnimation 0.75s ease-in-out forwards;
}
 