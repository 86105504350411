.Projects {
  width: 100%;
  height: auto;
  padding-top: 5vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
}

.Projects-title {
  font-family: var(--font-family-title);
  font-weight: 400;
  font-size: clamp(40px, 4.6vw, 90px);
  line-height: 100%;
  text-align: center;
  color: var(--color-primary);
}

.Projects-slider {
  margin: 5vh 1vw;
}

.Projects-slider-image {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.Projects-slider-image > img {
  width: 34vw;
  object-fit: contain;
}

.custom-slide-indicator {
  display: flex !important;
  align-items: center;
  width: 1.3vw;
  height: 1.3vw;
  transform: translate(-20%, -20%);
}
.custom-dot-indicator {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-self: center !important;
}

.custom-dot-indicator > li {
  background-color: var(--color-white);
  opacity: 0.7;
  width: 0.8vw !important;
  height: 0.8vw !important;
  border-radius: 50%;
  transform: translateY(5vh);
  margin: 1vh 1.5vw !important;
}

.custom-dot-indicator > li.slick-active {
  background-color: var(--color-primary);
  opacity: 1;
}

.imageOddIn {
  animation: upInAnimation 0.5s ease-in-out forwards;
}
.imageEvenIn {
  animation: downInAnimation 0.5s ease-in-out forwards;
}

.imageOddOut {
  animation: upOutAnimation 0.5s ease-in-out forwards;
}

.imageEvenOut {
  animation: downOutAnimation 0.5s ease-in-out forwards;
}
.sliderOut {
  opacity: 0;
  transition: 0.5s;
}

.Projects-content-card1,
.Projects-content-card2,
.Projects-content-card3,
.Projects-content-card4,
.Projects-content-card5,
.Projects-content-card6 {
  width: 40vw;
  min-height: 274px;
  display: flex;
  justify-content: space-between;
  border: 0.5px solid var(--color-primary);
  background-image: linear-gradient(
    to left,
    var(--color-primary) 0%,
    var(--color-black) 70%
  );
  border-radius: 12px;
  padding: 1rem 1rem 1rem 1.5rem;
  position: relative;
}
.Projects-content-card2 {
  border: 0.5px solid var(--color-banana-secondary);
  background-color: var(--color-banana-secondary);
  background-image: linear-gradient(
    to left,
    var(--color-banana-secondary) 0%,
    var(--color-black) 70%
  );
}
.Projects-content-card3 {
  background-image: linear-gradient(
    to left,
    var(--color-primary) 0%,
    var(--color-black) 60%
  );
}
.Projects-content-card4 {
  border: 0.5px solid var(--color-wolf-secondary);
  background-color: var(--color-wolf-secondary);
  background-image: linear-gradient(
    to left,
    var(--color-wolf-secondary) 0%,
    var(--color-black) 70%
  );
}
.Projects-content-card5 {
  border: 0.5px solid var(--color-alphacas-secondary);
  background-color: var(--color-alphacas-secondary);
  background-image: linear-gradient(
    to left,
    var(--color-alphacas-secondary) 0%,
    var(--color-black) 70%
  );
}
.Projects-content-card6 {
  border: 0.5px solid var(--color-foxes-secondary);
  background-color: var(--color-foxes-secondary);
  background-image: linear-gradient(
    to left,
    var(--color-foxes-secondary) 0%,
    var(--color-black) 70%
  );
}

.Projects-content-card1 > img,
.Projects-content-card2 > img,
.Projects-content-card3 > img,
.Projects-content-card4 > img,
.Projects-content-card5 > img,
.Projects-content-card6 > img {
  width: 45%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  align-self: flex-end;
  margin-bottom: -1em;
}
.Projects-content-card2 > img {
  width: 65%;
}
.Projects-content-card3 > img {
  visibility: hidden;
}
.Projects-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Projects-content-title1,
.Projects-content-title2,
.Projects-content-title3,
.Projects-content-title4,
.Projects-content-title5,
.Projects-content-title6 {
  font-family: var(--font-family-content-primary);
  font-weight: 600;
  font-size: clamp(25px, 2vw, 30px);
  line-height: 140%;
  list-style: none;
  color: var(--color-primary);
}
.Projects-content-title2 {
  color: var(--color-banana-primary);
}
.Projects-content-title4 {
  color: var(--color-wolf-primary);
}
.Projects-content-title5 {
  color: var(--color-alphacas-primary);
}
.Projects-content-title6 {
  color: var(--color-foxes-primary);
}

@media screen and (max-width: 1025px) {
  .custom-dot-indicator > li {
    width: 12px !important;
    height: 12px !important;
    margin: 1vh 2vw !important;
  }
  .Projects-slider-image > img {
    width: 43vw;
  }
  .Projects-content-card1,
  .Projects-content-card2,
  .Projects-content-card3,
  .Projects-content-card4,
  .Projects-content-card5,
  .Projects-content-card6 {
    width: 65vw;
    min-height: 217px;
  }
}

@media screen and (max-width: 1025px) and (orientation: landscape) {
  .Projects-slider-image > img {
    width: 35vw;
  }
}

@media screen and (max-width: 850px) and (orientation: portrait) {
  .Projects-slider-image > img {
    width: 65vw;
  }
}

@media screen and (max-width: 550px) {
  .Projects-content-card1,
  .Projects-content-card2,
  .Projects-content-card3,
  .Projects-content-card4,
  .Projects-content-card5,
  .Projects-content-card6 {
    width: 80vw;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 1rem;
  }
  .Projects-content-card1 {
    background-image: linear-gradient(
      to top,
      var(--color-primary) 0%,
      var(--color-black) 70%
    );
  }
  .Projects-content-card2 {
    background-image: linear-gradient(
      to top,
      var(--color-banana-secondary) 0%,
      var(--color-black) 70%
    );
  }
  .Projects-content-card3 {
    background-image: linear-gradient(
      to top,
      var(--color-primary) 0%,
      var(--color-black) 50%
    );
    mix-blend-mode: hard-light !important;
    justify-content: center;
  }
  .Projects-content-card4 {
    background-image: linear-gradient(
      to top,
      var(--color-wolf-secondary) 0%,
      var(--color-black) 70%
    );
  }
  .Projects-content-card5 {
    background-image: linear-gradient(
      to top,
      var(--color-alphacas-secondary) 0%,
      var(--color-black) 70%
    );
  }
  .Projects-content-card6 {
    background-image: linear-gradient(
      to top,
      var(--color-foxes-secondary) 0%,
      var(--color-black) 70%
    );
  }
  .Projects-content {
    width: auto;
    text-align: center;
  }
  .Projects-content-title3 {
    color: var(--color-primary);
  }
  .Projects-content-card1 > img,
  .Projects-content-card2 > img,
  .Projects-content-card3 > img,
  .Projects-content-card4 > img,
  .Projects-content-card5 > img,
  .Projects-content-card6 > img {
    width: 85%;
    height: auto;
    position: unset;
    align-self: center;
    margin-bottom: -1.12rem;
  }
  .Projects-content-card2 > img {
    width: 92%;
  }
  .Projects-content-card3 > img {
    display: none !important;
    object-fit: cover;
    object-position: bottom;
    height: 160px;
  }
}
