.OurStory {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.OurStory-content {
    margin: 10vh 2vw 2vh 2vw;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.OurStory-content-title {
    font-family: var(--font-family-title);
    font-weight: 400;
    font-size: clamp(40px, 4.6vw, 90px);
    line-height: clamp(40px, 4.6vw, 90px);
    text-align: center;
    color: var(--color-primary);
}

.OurStory-content-description {
    font-family: var(--font-family-content-primary);
    font-weight: 400;
    font-size: clamp(20px, 1.7vw, 40px);
    line-height: 150%;
    text-align: center;

    color: var(--color-white);

    margin: 2vh 2vw;
}

.OurStory-content-description>section {
    margin: 3vh 0;
}

.storyIn {
    animation: upInAnimation 0.75s ease-in-out forwards;
}
.storyOut {
    animation: upOutAnimation 0.5s ease-in-out forwards;
}


@media screen and (max-width:1025px) {
    .OurStory-content {
        width: 100%;
        padding: 0 1.5rem;
    }
}